function main() {
	var playOnHover = document.querySelectorAll(".js-playOnHover");

	for (var i = playOnHover.length - 1; i >= 0; i--) {
		playOnHover[i].onmouseenter = ((e) => {e.target.play()});
		playOnHover[i].onmouseout   = ((e) => {e.target.pause()});
	}
}

document.addEventListener("DOMContentLoaded", function() {
	main();
})